const VersionNumber = "1.4.3";
const ChangeLogList = [
  {
    date: "21/02/2024",
    type: "Bug fixes",
    details: "Resolved issue with duplicate users in SarStuff Central with the same phone number incorrectly being selected on SMS replies.",
  },
  {
    date: "10/02/2024",
    type: "Bug fixes",
    details: "Resolved issue with the incorrect inbox being selected for incoming text messages.",
  },
  {
    date: "06/11/2023",
    type: "Bug fixes",
    details: "Resolved various reported issues.",
  },
  {
    date: "05/04/2023",
    type: "Enhancement",
    details: "Added more backend service functions related to SarSYS Request.",
  },
  {
    date: "27/03/2023",
    type: "Bug fixes",
    details: "Resolved issue where adding a log entry relating to a decision needing to be made not being linked correctly.",
  },
  {
    date: "15/12/2022",
    type: "Bug fixes",
    details: "Resolved issue where adding a log entry to the global log without a any job selected would not save.",
  },
  {
    date: "04/08/2022",
    type: "Enhancement",
    details: "Security enhancements.", // Updated the SSO plugin to support session timeout
  },
  {
    date: "26/07/2022",
    type: "Bug fixes",
    details: "Resolved issue where welfare checks was disabled in settings it still kept being prompted.",
  },
  {
    date: "20/07/2022",
    type: "Enhancement",
    details: "SarSYS [CAD] now with faster servers... Ooooo.",
  },
  {
    date: "27/05/2022",
    type: "Bug fixes",
    details:
      "Radio Assets have offset labels if two responders in the same address.",
  },
  {
    date: "17/05/2022",
    type: "Bug fixes",
    details:
      "Resource map on job now has offset labels if two responders in the same address.",
  },
  {
    date: "22/04/2022",
    type: "Bug fixes",
    details:
      "Staff List and Resource List (on jobs) now show the correct status coloring matching TeamSite style.",
  },
  {
    date: "12/04/2022",
    type: "Enhancement",
    details:
      "Added support for live streaming from the SarSYS [CAD] Mobile App and external RTMP assets (created in SarStuff Central).",
  },
  {
    date: "07/04/2022",
    type: "Enhancement",
    details: "Backend update to support tracking via the mobile app.",
  },
  {
    date: "06/04/2022",
    type: "Enhancement",
    details:
      "Job archive page ability to mass export job debriefs based on date range.",
  },
  {
    date: "25/03/2022",
    type: "Bug fixes",
    details: "Fixed many minor bugs on both frontend and backend.",
  },
  {
    date: "16/03/2022",
    type: "Enhancement",
    details:
      "Team members secondary phone number now receives SMS messages in addition to there primary phone number.",
  },
  {
    date: "14/03/2022",
    type: "Bug fixes",
    details:
      "Fixed: Unable to remove 'Responder Status' in settings, Fixed: Map center from central not been pulled in once updated.",
  },
  {
    date: "14/03/2022",
    type: "Enhancement",
    details: "Added change log display within settings.",
  },
];

export { VersionNumber, ChangeLogList };
